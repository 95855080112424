const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://9kj4jncln7.execute-api.ap-southeast-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://qrvjgq4aag.execute-api.ap-southeast-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.ozminerals.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'Australia/Sydney'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.ozminerals.forwoodsafety.com'
  }
};

export default config;